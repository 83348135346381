import { useAuth0 } from "@auth0/auth0-react";
import { navigate } from "gatsby-link";
import React from "react";

interface RegionGuardProps {
  currentLang: string;
}

const RegionGuard: React.FC<RegionGuardProps> = ({ currentLang, children }) => {
  const { user } = useAuth0();
  const NAMESPACE = "contentguru";
  const REGION_KEY = `${NAMESPACE}/allowed_regions`;
  const allowedRegions = (user && user[REGION_KEY]) || [];
  const hasAccess = allowedRegions.includes(currentLang);

  if (!hasAccess) {
    navigate(`/${currentLang}/trust-centre/region-not-allowed`, {
      replace: true,
    });
    return null;
  }

  return <>{children}</>;
};
export default RegionGuard;
